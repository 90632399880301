<template>
  <div>
    <DxPopup
      id="attach-file-popup"
      v-bind="commonProps.popup"
      :title="$t(tradRoot + '.title')"
      :visible.sync="popupShow"
      :on-hidden="onHidden"
      height="auto"
      :width="700"
    >
      <DxForm
        ref="form"
        :show-colon-after-label="false"
      >
        <DxSimpleItem
          :data-field="$t(tradRoot + '.description')"
          editor-type="dxTextBox"
          :editor-options="{
            value: fileDisplayName,
            onValueChanged: onChangeDisplayName,
            placeholder: this.$t(tradRoot + '.descriptionPlaceholder'),
          }"
        >
          <DxRequiredRule
            :message="$t(tradRoot + '.descriptionRequired')"
          />
        </DxSimpleItem>
        <DxSimpleItem
          :data-field="$t(tradRoot + '.grades')"
          editor-type="dxTextArea"
          :editor-options="{
            value: fileDescription,
            onValueChanged: onChangeDescription,
            placeholder: this.$t(tradRoot + '.gradesPlaceholder'),
            height: 85,
          }"
        >
          <DxRequiredRule
            :message="$t(tradRoot + '.gradesRequired')"
          />
        </DxSimpleItem>
      </DxForm>
      <FileUploader :id="Id" />
      <f7-block-footer
        class="content-action-botton"
      >
        <div class="content-buttons margin-vertical">
          <DxButton
            class="dx-btn-success padding-half"
            type="success"
            styling-mode="contained"
            name="ButtonSave"
            @click="savePopup"
          >
            {{ $t(tradRoot + '.saveFile') }}
          </DxButton>
        </div>
      </f7-block-footer>
    </DxPopup>
    <DxButton
      class="dx-btn-file"
      raised
      type="default"
      :disabled="!currentShip.editPermissions"
      styling-mode="outlined"
      @click="openPopupFile"
    >
      <f7-icon
        md="f7:arrow_up_doc"
        class="color-black"
      />
    </DxButton>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';
import 'devextreme-vue/text-area';

import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import FileUploader from './fileUploader/index.vue';

import eventBus from '../../../js/event-bus';
import { commonProps } from '../../trips/tripsColumnData';

export default {
  name: 'AttachFilePopup',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxButton,
    FileUploader,
  },
  data() {
    return {
      commonProps,
      tradRoot: 'deviceInfo.documents.fileAttachPopup',
      popupShow: false,
      fileDescription: '',
      fileDisplayName: '',
      Id: '',
    };
  },
  computed: {
    form() {
      return this.$refs.form.instance;
    },
    ...mapState('Shipments', ['file', 'currentShip']),
  },
  mounted() {
    eventBus.$on('noUserPermisions', () => {
      this.$f7.dialog.alert(this.$t('deviceInfo.documents.errors.noUserPermisions'));
    });
    eventBus.$on('noExist', () => {
      this.$f7.dialog.alert(this.$t('deviceInfo.documents.errors.noExist'));
    });
    eventBus.$on('openPopupFile', (guid) => {
      this.Id = guid.Id;
      this.fileDescription = guid.Description;
      this.fileDisplayName = guid.DisplayName;
      this.setFileId(guid.Id);
      this.setFileDescription(this.fileDescription);
      this.setFileDisplayName(this.fileDisplayName);
      this.popupShow = true;
    });
  },
  methods: {
    openPopupFile() {
      this.Id = '';
      this.setFileId(this.Id);
      this.popupShow = true;
    },
    onHidden() {
      this.form.resetValues();
      eventBus.$emit('clearFileUploader');
    },
    async savePopup() {
      if (this.file.Id === '') {
        if (this.file.Description !== '' && this.file.Name !== '' && this.file.Attachment !== '' && this.file.Extension !== '') {
          this.save();
        } else {
          this.$f7.dialog.alert(this.$t(`${this.tradRoot}.message.allFields`));
        }
      } else if (this.file.Id !== '') {
        if (this.file.Description !== '' && this.file.DisplayName !== '') {
          this.save();
        } else {
          this.$f7.dialog.alert(this.$t(`${this.tradRoot}.message.allFields`));
        }
      }
    },
    async save() {
      try {
        this.$f7.preloader.show();
        await this.uploadFile();
      } catch (e) {
        console.debug(e);
      } finally {
        this.$f7.preloader.hide();
        this.form.resetValues();
        eventBus.$emit('clearFileUploader');
        this.popupShow = false;
        await this.getFilesList();
      }
    },
    onChangeDescription(e) {
      this.fileDescription = e.value;
      this.setFileDescription(this.fileDescription);
    },
    onChangeDisplayName(e) {
      this.fileDisplayName = e.value;
      this.setFileDisplayName(this.fileDisplayName);
    },
    ...mapActions('Shipments', ['uploadFile', 'setFileDescription', 'setFileDisplayName', 'getFilesList', 'setFileId']),
  },
};
</script>
<style lang="scss" scoped>
@import './AttachFilePopup.styles.scss';
</style>
