import Api from '../../services/Api';
import Recipients from '../../js/models/Recipients';
import Cargos from '../../js/models/Cargos';
import Origins from '../../js/models/Origins';
import Destinations from '../../js/models/Destinations';
import helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    cargos: [],
    origins: [],
    destinations: [],
    recipientsInfo: [],
    isOriginBlock: false,
    isDestinationBlock: false,
    userData: null,
    comercialCasi: [
      'Arturo Gisbert Gallego',
      'Jose Carlos Escobar Martinez',
      'Francisco Fuentes Cespedes',
      'Virginie Kinh',
      'Manuel Segura Sánchez',
      'Antonio Zaragoza',
    ],
    preReferenciaFrutaria: [
      '02/',
      '04/',
      '05/',
      '06/',
    ],
    codPreferenciaFrutaria: '',
    originsSanLucarTunez: [
      'LA CINQUIEME SAISON',
      'FLOR´ALIA',
      'LES PERLES DU DESERT',
    ],
    productsSanLucarTunez: [
      'TOMATOES',
      'RASPBERRYS',
      'STRAWBERRIES',
      'BLUEBERRIES',
    ],
    carriersSanLucarTunez: [
      'VECTORYS',
      'SINDBAD',
      'GERMANETTI',
      'EK',
      'QR',
      'SV',
      'TK',
      'OTHERS',
    ],
    destinationsSanLucarTunez: [
      'ETTLINGEN',
      'DUBAI',
      'BAHRAIN',
      'POLOGNE',
      'VIENNA',
      'RUSSIE',
      'RIYADH',
      'QATAR',
      'ALDI HOFER',
      'KUWAIT',
      'HILLFRESH',
      'DOHA',
      'OMAN',
      'SAUDI ARABIA',
      'MAGNIT',
      'MUSCAT',
      'NETHERLANDS',
      'UK',
      'DAMMAM',
      'ITALIE',
      'LEHMANN',
      'SANLUCAR',
      'ALDIHOFER',
      'JEDDAH',
      'KSA',
      'KWI',
      'DMM',
      'LETTONIE',
      'ADEL SEDIK',
      'CHARBATLI',
      'FRANKFURT',
      'GENE',
    ],
    carriersPlanasaMoroco: [
      'SAOUIR',
      'GTO',
      'JCARRION',
      'BOLIPESK',
      'TRANSMORO',
      'CMA',
    ],
    productsPlanasaMoroco: [
      'RASPBERRY ROOTBALL PLANT',
      'STRAWBERRY ROOTBALL PLANT',
      'BLUEBERRY PLANT',
      'STRAWBERRY RUNNERS',
      'BLACKBERRIES',
      'AVOCADO',
      'RASPBERRY FRUIT',
    ],
    destinationsPlanasaMoroco: [
      'SPAIN',
      'FRANCE',
      'NETHERLANDS',
      'BELGIUM',
      'GERMANY',
      'MOROCCO',
    ],
    clientsPlanasaMoroco: [
      'ANJOU PLANTS FRANCE',
      'PLANASA VIVEROS',
      'IVO SCHOUBS',
      'PLANASA FRANCE',
      'BIOSEM',
      'BOURBON',
      'CAPDROT',
      'CERDYS MELLORAY',
      'CERDYS NONNAINS',
      'EARL DES PUITS',
      'GUYENNE',
      'KAACK',
      'LES FERRIERES',
      'MARTAILLAC',
      'MATIKA BV',
      'PEPINIERES DE SOLOGNE',
      'PEPINIERES DROT',
      'PROVENCE PLANTS',
      'RAZIMET',
      'SAS LARCHE',
      'TECNOSEM',
      'THOMAS PLANTS',
      'VERPAALEN',
      'FRUITS ROUGES CO',
      'NATURES PRIDE',
      'SPECIAL FRUIT',
    ],
  },
  getters: {
    getCargos(state) {
      return state?.cargos;
    },
    getOrigins(state) {
      return state?.origins;
    },
    getDestinations(state) {
      return state?.destinations;
    },
    getRecipientInfo(state) {
      return state?.recipientsInfo;
    },
    getComercialCasi(state) {
      return state?.comercialCasi;
    },
    getPreReferenciaFrutaria(state) {
      return state?.preReferenciaFrutaria;
    },
    getCodPreReferenciaFrutaria(state) {
      return state?.codPreferenciaFrutaria;
    },
    isOriginBlock(state) {
      return state?.isOriginBlock;
    },
    isDestinationBlock(state) {
      return state?.isDestinationBlock;
    },
    getCarriers(state) {
      return state?.carriers;
    },
    getOriginsSanLucarTunez(state) {
      return state?.originsSanLucarTunez;
    },
    getProductsSanLucarTunez(state) {
      return state?.productsSanLucarTunez;
    },
    getCarriersSanLucarTunez(state) {
      return state?.carriersSanLucarTunez;
    },
    getDestinationsSanLucarTunez(state) {
      return state?.destinationsSanLucarTunez;
    },
    getCarriersPlanasaMoroco(state) {
      return state?.carriersPlanasaMoroco;
    },
    getProductsPlanasaMoroco(state) {
      return state?.productsPlanasaMoroco;
    },
    getDestinationsPlanasaMoroco(state) {
      return state?.destinationsPlanasaMoroco;
    },
    getClientsPlanasaMoroco(state) {
      return state?.clientsPlanasaMoroco;
    },
  },
  mutations: {
    UPDATE_CARGOS_LIST(state, payload) {
      state.cargos = payload;
    },
    UPDATE_ORIGINS_LIST(state, payload) {
      state.origins = payload;
    },
    UPDATE_DESTINATIONS_LIST(state, payload) {
      state.destinations = payload;
    },
    UPDATE_USERDATA_LIST(state, payload) {
      state.userData = payload;
    },
    UPDATE_RECIPIENTINFO_LIST(state, payload) {
      state.recipientsInfo = payload;
    },
    UPDATE_CARRIERS(state, payload) {
      state.carriers = payload;
    },
    UPDATE_ORIGINSSANLUCARTUNEZ(state, payload) {
      state.originsSanLucarTunez = payload;
    },
    UPDATE_PRODUCTSSANLUCARTUNEZ(state, payload) {
      state.productsSanLucarTunez = payload;
    },
    UPDATE_CARRIERSSANLUCARTUNEZ(state, payload) {
      state.carriersSanLucarTunez = payload;
    },
    UPDATE_DESTINATIONSSANLUCARTUNEZ(state, payload) {
      state.destinationsSanLucarTunez = payload;
    },
    UPDATE_CARRIERSPLANASAMOROCO(state, payload) {
      state.carriersPlanasaMoroco = payload;
    },
    UPDATE_PRODUCTSPLANASAMOROCO(state, payload) {
      state.productsPlanasaMoroco = payload;
    },
    UPDATE_DESTINATIONSPLANASAMOROCO(state, payload) {
      state.destinationsPlanasaMoroco = payload;
    },
    UPDATE_CLIENTSPLANASAMOROCO(state, payload) {
      state.clientsPlanasaMoroco = payload;
    },
  },
  actions: {
    async fetchCargosList({ commit }) {
      try {
        const data = await Api.cargos.getAll();
        // const data = await Api.merchandise.getAll();
        if (data.status !== 404) {
          commit('UPDATE_CARGOS_LIST', JSON.parse(data.response));
        }
      } catch (error) {
        commit('UPDATE_CARGOS_LIST', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async fetchOriginsList({ commit }) {
      try {
        const data = await Api.origins.getAll();
        commit('UPDATE_ORIGINS_LIST', JSON.parse(data.response));
      } catch (error) {
        commit('UPDATE_ORIGINS_LIST', []);

        const msg = helpers.getFilteredErrorMessage(error);
        return Promise.reject(msg);
      }
      return Promise.resolve();
    },
    async fetchDestinationsList({ commit }) {
      const data = await Destinations.getAll();
      commit('UPDATE_DESTINATIONS_LIST', data);
    },
    async fetchRecipientInfo({ commit }) {
      // const data = await Recipients.getAll();
      const data = await Api.recipientInfo.getAll();
      commit('UPDATE_RECIPIENTINFO_LIST', JSON.parse(data.response));
    },
    async fetchCarriers({ commit }) {
      const data = await Api.carriers.getAll();
      commit('UPDATE_CARRIERS', JSON.parse(data.response));
    },
  },
};
