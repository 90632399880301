import moment from 'moment';
import { commonProps } from '../trips/tripsColumnData';
import $ from '../../js/i18n.config';
import powerIcon from '../../static/icons/ic_power.svg';
import editIcon from '../../static/icons/ic_edit_light.svg';
import viewIcon from '../../static/icons/ic_more.svg';
import pdfIcon from '../../static/icons/ic_pdf_light.svg';
import claimedIcon from '../../static/icons/ic_task.svg';
import { baseUrlAPI } from '../../js/constants';
import eventBus from '../../js/event-bus';

function formatDate(e) {
  return moment(e).format('DD/MM/YYYY');
}
const highRange = (row) => {
  if (row.temperature > row.higherTemperature) return true;
  return false;
};
const lowRange = (row) => {
  if (row.temperature < row.lowerTemperature) return true;
  return false;
};
const inRange = (row) => {
  if (!highRange(row) && !lowRange(row)) return true;
  return false;
};
function getTempFilters(filterValue) {
  const column = this;
  switch (filterValue) {
    case 'inRange':
      return [inRange, '=', true];
    case 'highRange':
      return [highRange, '=', true];
    case 'lowRange':
      return [lowRange, '=', true];
    default:
      break;
  }
  // eslint-disable-next-line prefer-spread
  return column.defaultCalculateFilterExpression.apply(column, arguments);
}
function createFilters(data) {
  data.dataSource.postProcess = () => {
    const filterTypes = [
      { key: 'inRange', value: 'inRange', text: $.t('common.temperature.inRange') },
      { key: 'highRange', value: 'highRange', text: $.t('common.temperature.highRange') },
      { key: 'lowRange', value: 'lowRange', text: $.t('common.temperature.lowRange') },
    ];
    return filterTypes;
  };
}
export const colMethods = {
  async exportPdf(e) {
  /* const deviceId = e.row.data.device;
    await this.downloadFinalSummaryPDF(deviceId); */
    const deviceId = e.row.data.device;

    window.URL = window.URL || window.webkitURL;
    const xhr = new XMLHttpRequest();
    const a = document.createElement('a');
    let file;

    const fileName = (deviceId || '').split('/').pop().split('?')[0];

    const baseUrl = `${baseUrlAPI}/v1/devices/${deviceId}/pdf`;
    xhr.open('GET', baseUrl, true);
    xhr.setRequestHeader('Authorization', `Bearer ${this.user.access}`);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      file = new Blob([xhr.response], { type: 'application/pdf' });
      a.href = window.URL.createObjectURL(file);
      a.download = `${fileName}.pdf`;
      a.click();
    };
    xhr.send();
  },
  goToShip(e) {
    window.open(`/#!/shipInformation/deviceInfo/${e.row.data.device}`, '_blank');
  },
  openPopupDeactivate(e) {
    eventBus.$emit('openDeactivatePopup', e.row.data.device);
  },
  openLaunchTrip(e) {
    eventBus.$emit('openLaunchTrip', e.row.data.device);
  },
  openClaimPopup(e) {
    eventBus.$emit('openClaimPopup', e.row.data.device);
  },
  addButtonFuncs(buttons, visible = false) {
    buttons.forEach((button) => {
      switch (button.name) {
        case 'pdf':
          button.onClick = this.exportPdf;
          break;
        case 'deactivate':
          button.onClick = this.openPopupDeactivate;
          break;
        case 'claimedByCustomer':
          button.visible = visible;
          button.onClick = this.openClaimPopup;
          break;
        case 'editDevice':
          button.onClick = this.openLaunchTrip;
          break;
        case 'moreDeviceInfo':
          button.onClick = this.goToShip;
          break;
        default:
          break;
      }
    });
  },
};

export const dataGridProps = {
  ...commonProps.dataGrid,
  scrolling: {
    columnRenderingMode: 'standard',
  },
  searchPanel: {
    visible: true,
    width: 240,
    placeholder: $.t('shipping.search.placeholder'),
  },
  paging: {
    pageSize: 50,
  },
  grouping: {
    autoExpandAll: true,
  },
  headerFilter: {
    visible: true,
  },
  filterRow: {
    visible: true,
  },
  columnChooser: {
    enabled: true,
    mode: 'select',
  },
  stateStoring: {
    enabled: true,
    storageKey: 'SearchEngine',
  },
  pager: {
    showPageSizeSelector: true,
    allowedPageSizes: [10, 20, 50, 100],
    showInfo: true,
  },
};
export const dictionary = {
  Alert: 1,
  Reference: 2,
  Temp: 3,
  Location: 4,
  LastReport: 5,
  Origin: 6,
  Activation: 7,
  Client: 8,
  Destination: 9,
  Delivery: 10,
  NumberTransport: 11,
  Carrier: 12,
  Range: 13,
  Cargo: 14,
  Device: 15,
  CustomerRef: 16,
  Humidity: 17,
  CO2: 18,
  Week: 19,
  PulpTemp: 20,
  TypeTransport: 21,
  O2: 22,
  OriginPort: 23,
  DestinationPort: 24,
  Etd: 25,
  Eta: 26,
  ContainerNumber: 27,
  Consignee: 28,
};
export default [
  {
    name: 'Status',
    dataField: 'status',
    caption: $.t('shipping.status'),
    alignment: 'center',
    width: 70,
    cellTemplate: 'status-cell',
    allowSorting: true,
    allowFiltering: false,
    allowHeaderFiltering: false,
  },
  {
    name: 'Alert',
    dataField: 'alert',
    caption: $.t('common.track.alert'),
    alignment: 'center',
    width: 90,
    cellTemplate: 'alert-cell',
    allowHeaderFiltering: false,
  },
  {
    name: 'Device',
    dataField: 'device',
    caption: $.t('common.track.device'),
    alignment: 'center',
    width: 90,
    allowHeaderFiltering: false,
  },
  {
    name: 'Temp',
    dataField: 'temperature',
    caption: $.t('common.track.temperature'),
    alignment: 'center',
    dataType: 'number',
    cellTemplate: 'temp-cell',
    calculateFilterExpression: getTempFilters,
    headerFilter: {
      dataSource: createFilters,
    },
    filterRow: {
      visible: true,
      searchMode: 'equals',
    },
    allowHeaderFiltering: true,
  },
  {
    name: 'Location',
    dataField: 'location',
    caption: $.t('shipping.location'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,
  },
  {
    name: 'LastReport',
    dataField: 'lastReport',
    caption: $.t('common.track.lastReport'),
    alignment: 'center',
    width: 110,
    allowHeaderFiltering: false,
    calculateSortValue: 'dateTimeAcquiredUtc',
  },
  {
    name: 'Origin',
    dataField: 'origin',
    caption: $.t('shipping.origin'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Activation',
    dataField: 'dateStart',
    caption: $.t('common.date.dateStart'),
    alignment: 'center',
    width: 110,
    format: {
      formatter: formatDate,
    },
  },
  {
    name: 'CustomerRef',
    dataField: 'customerRef',
    caption: $.t('common.track.reference'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,
  },
  {
    name: 'Client',
    dataField: 'customerOfOurCustomer',
    caption: $.t('shipping.customerOfOurCustomer'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,
  },
  {
    name: 'Destination',
    dataField: 'destination',
    caption: $.t('shipping.destination'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,
  },
  {
    name: 'Delivery',
    dataField: 'dateArrived',
    caption: $.t('common.date.dateDeliver'),
    alignment: 'center',
    sortOrder: 'desc',
    width: 120,
    cellTemplate: 'arrival-cell',
    format: {
      formatter: formatDate,
    },
  },
  {
    name: 'NumberTransport',
    dataField: 'numberTransport',
    caption: $.t('shipping.numberTransport'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Carrier',
    dataField: 'carrier',
    caption: $.t('common.blocks.carrier'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,

  },
  {
    name: 'Humidity',
    dataField: 'humidity',
    caption: $.t('common.track.humidity'),
    alignment: 'center',
    allowHeaderFiltering: true,
    cellTemplate: 'humidity-cell',

  },
  {
    name: 'CO2',
    dataField: 'co2',
    caption: $.t('common.track.co2'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'O2',
    dataField: 'o2',
    caption: $.t('common.track.o2'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Week',
    dataField: 'week',
    caption: $.t('common.track.week'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'PulpTemp',
    dataField: 'pulpTemp',
    caption: $.t('common.track.pulpTemp'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Range',
    dataField: 'range',
    caption: $.t('shipping.range'),
    alignment: 'center',
    width: 90,
    allowHeaderFiltering: false,
  },
  {
    name: 'Cargo',
    dataField: 'cargo',
    caption: $.t('common.blocks.cargo'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Device',
    dataField: 'device',
    caption: $.t('common.blocks.device'),
    alignment: 'center',
    width: 130,
    allowHeaderFiltering: false,
  },
  {
    name: 'TypeTransport',
    dataField: 'typeTransport',
    caption: $.t('common.blocks.typeTransport'),
    alignment: 'center',
    width: 120,
    allowHeaderFiltering: true,
    cellTemplate: 'typeTransport-cell',
  },
  {
    name: 'OriginPort',
    dataField: 'originPort',
    caption: $.t('common.blocks.originPort'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'DestinationPort',
    dataField: 'destinationPort',
    caption: $.t('common.blocks.destinationPort'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Etd',
    dataField: 'etd',
    caption: $.t('common.blocks.etd'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Eta',
    dataField: 'eta',
    caption: $.t('common.blocks.eta'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'ContainerNumber',
    dataField: 'containerNumber',
    caption: $.t('common.blocks.containerNumber'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    name: 'Consignee',
    dataField: 'consignee',
    caption: $.t('common.blocks.consignee'),
    alignment: 'center',
    allowHeaderFiltering: true,
  },
  {
    type: 'buttons',
    showInColumnChooser: false,
    buttons: [
      {
        name: 'pdf',
        icon: pdfIcon,
        hint: $.t('common.toolTips.newReport'),
      },
      {
        name: 'deactivate',
        icon: powerIcon,
        hint: $.t('common.toolTips.desactive'),
      },
      {
        name: 'claimedByCustomer',
        icon: claimedIcon,
        hint: $.t('common.toolTips.makeClaim'),
      },
      {
        name: 'editDevice',
        icon: editIcon,
        hint: $.t('common.toolTips.edit'),
      },
      {
        name: 'moreDeviceInfo',
        icon: viewIcon,
        hint: $.t('common.toolTips.moreInfo'),
      },
    ],
  },
];
