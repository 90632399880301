<template>
  <div>
    <div
      ref="googleMap"
      class="main-map"
    />
    <template v-if="Boolean(google) && Boolean(map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import eventBus from '../../../js/event-bus';

export default {
  props: {
    getPosition: {
      type: Boolean,
      default: false,
    },
    apiKey: { type: String, default: '' },
    mapConfig: { type: Object, default: null },
  },

  data() {
    return {
      google: null,
      map: null,
    };
  },

  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
      libraries: ['places'],
    });
    this.$root.$emit('googleReady', this.google);
    this.initializeMap();
    this.initializeAutocompleteData();
    if (this.getPosition) {
      const { Marker } = this.google.maps;
      const positionMarker = new Marker();
      eventBus.$on('setMarkerPosition', (data) => {
        if (!data) {
          this.map.setCenter(this.mapConfig.center);
          positionMarker.setMap(null);
        } else {
          const pos = {
            lat: data.latitude,
            lng: data.longitude,
          };
          this.map.setCenter(pos);
          positionMarker.setMap(this.map);
          positionMarker.setPosition(pos);
        }
      });
      this.map.addListener('click', (e) => {
        eventBus.$emit('mapMarkerPosition', { lat: e.latLng.lat(), lng: e.latLng.lng() });
        positionMarker.setMap(this.map);
        positionMarker.setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      });
    }
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
    },
    initializeAutocompleteData() {
      const displaySuggestions = (predictions, status) => {
        if (status !== this.google.maps.places.PlacesServiceStatus.OK || !predictions) {
          eventBus.$emit('autocompletePredictions', []);
          return;
        }

        eventBus.$emit('autocompletePredictions', predictions);
      };

      const service = new this.google.maps.places.AutocompleteService();

      eventBus.$on('getAutoCompletePredictions', (query) => {
        service.getQueryPredictions({ input: query }, displaySuggestions);
      });
    },
  },
};
</script>

<style scoped>
@import url('LoadMap.styles.scss');
</style>
